import React, { useState, useEffect, useLayoutEffect, useContext } from "react"
import { Link, navigate, useStaticQuery, graphql } from "gatsby"

import { useAuthContext } from "../../context/AuthContext"

import ImgNavbarLogoDark from "../../assets/img/brand/logo_bsd-dark.png"
import ImgNavbarLogoLight from "../../assets/img/brand/logo_bsd-light.png"

import MobileMenuAlt from "./mobile-menu-alt"
import { LangContext } from "../../context/LangContext"

const sapKey = process.env.SAP_API_KEY

const NavbarAlt = () => {
  const data = useStaticQuery(graphql`
    query {
      wordPress {
        menus {
          nodes {
            name
            slug
            menuItems {
              nodes {
                url
                label
                childItems {
                  nodes {
                    label
                    url
                    childItems {
                      nodes {
                        label
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const { user, setUser } = useAuthContext()
  const { lang, setLang } = useContext(LangContext)

  useLayoutEffect(() => {
    // load SAP cdn at header
    ;(function loadSAPCdn() {
      var script = document.createElement("script")
      script.src = `https://cdns.gigya.com/js/gigya.js?apikey=${sapKey}`
      document.getElementsByTagName("script")[0].parentNode.appendChild(script)
    })()
  })
  // Navbar expand config
  // Change the const below & config on _navbar.scss when setting the .navbar-expand-*
  // e.g. sm, md, lg, xl
  const navExpand = "lg"

  const [desktopSubmenu, setDesktopSubmenu] = useState({
    initial: false,
    clicked: null,
  })
  const [desktopAboutSubmenu, setDesktopAboutSubmenu] = useState({
    initial: false,
    clicked: null,
  })
  const [desktopServicesSubmenu, setDesktopServicesSubmenu] = useState({
    initial: false,
    clicked: null,
  })

  // Search - MOBILE
  // Open / close menu state & handler
  const [navScroll, setNavScroll] = useState(false)
  const [search, setSearch] = useState(false)
  const clickSearch = () => {
    setSearch(!search)
    setNavScroll(true)
  }
  const handlerSearch = () => {
    setSearch(!search)
  }

  const handleDesktopSubmenu = arg => {
    disableSubmenuToggler()
    if (arg !== arg) {
      setDesktopSubmenu({ clicked: !desktopSubmenu.clicked, type: arg })
    }
    if (desktopSubmenu.initial === false) {
      setDesktopSubmenu({ initial: null, clicked: true, type: arg })
      setDesktopAboutSubmenu({ initial: null, clicked: false, type: arg })
      setDesktopServicesSubmenu({ initial: null, clicked: false, type: arg })
    } else if (desktopSubmenu.clicked === true) {
      setDesktopSubmenu({ clicked: false })
      setDesktopAboutSubmenu({ clicked: false })
      setDesktopServicesSubmenu({ initial: null, clicked: false, type: arg })
    } else if (desktopSubmenu.clicked === false) {
      setDesktopSubmenu({ clicked: true, type: arg })
      setDesktopAboutSubmenu({ clicked: false, type: arg })
      setDesktopServicesSubmenu({ initial: null, clicked: false, type: arg })
    }
  }

  const handleAboutDesktopSubmenu = arg => {
    disableAboutSubmenuToggler()
    if (arg !== arg) {
      setDesktopAboutSubmenu({ clicked: !desktopSubmenu.clicked, type: arg })
    }
    if (desktopAboutSubmenu.initial === false) {
      setDesktopSubmenu({ initial: null, clicked: false, type: arg })
      setDesktopAboutSubmenu({ initial: null, clicked: true, type: arg })
      setDesktopServicesSubmenu({ initial: null, clicked: false, type: arg })
    } else if (desktopAboutSubmenu.clicked === true) {
      setDesktopSubmenu({ initial: null, clicked: false, type: arg })
      setDesktopAboutSubmenu({ initial: null, clicked: false, type: arg })
      setDesktopServicesSubmenu({ initial: null, clicked: false, type: arg })
    } else if (desktopAboutSubmenu.clicked === false) {
      setDesktopSubmenu({ initial: null, clicked: false, type: arg })
      setDesktopAboutSubmenu({ initial: null, clicked: true, type: arg })
      setDesktopServicesSubmenu({ initial: null, clicked: false, type: arg })
    }
  }

  const handleServicesDesktopSubmenu = arg => {
    disableServicesSubmenuToggler()
    if (arg !== arg) {
      setDesktopServicesSubmenu({ clicked: !desktopSubmenu.clicked, type: arg })
    }
    if (desktopServicesSubmenu.initial === false) {
      setDesktopSubmenu({ initial: null, clicked: false, type: arg })
      setDesktopAboutSubmenu({ initial: null, clicked: false, type: arg })
      setDesktopServicesSubmenu({ initial: null, clicked: true, type: arg })
    } else if (desktopServicesSubmenu.clicked === true) {
      setDesktopSubmenu({ initial: null, clicked: false, type: arg })
      setDesktopAboutSubmenu({ initial: null, clicked: false, type: arg })
      setDesktopServicesSubmenu({ initial: null, clicked: false, type: arg })
    } else if (desktopServicesSubmenu.clicked === false) {
      setDesktopSubmenu({ initial: null, clicked: false, type: arg })
      setDesktopAboutSubmenu({ initial: null, clicked: false, type: arg })
      setDesktopServicesSubmenu({ initial: null, clicked: true, type: arg })
    }
  }

  // Toggle submenu toggler disabled state & handler
  const [submenuToggler, setSubmenuToggler] = useState(false)
  const disableSubmenuToggler = () => {
    setSubmenuToggler(!submenuToggler)
    setTimeout(() => {
      setSubmenuToggler(false)
    }, 1000)
  }

  const [aboutSubmenuToggler, setAboutSubmenuToggler] = useState(false)
  const disableAboutSubmenuToggler = () => {
    setAboutSubmenuToggler(!submenuToggler)
    setTimeout(() => {
      setAboutSubmenuToggler(false)
    }, 1000)
  }

  const [servicesSubmenuToggler, setServicesSubmenuToggler] = useState(false)
  const disableServicesSubmenuToggler = () => {
    setServicesSubmenuToggler(!submenuToggler)
    setTimeout(() => {
      setServicesSubmenuToggler(false)
    }, 1000)
  }

  // MENU - MOBILE
  // Open / close menu state & handler
  const [mobileMenu, setMobileMenu] = useState({
    initial: false,
    clicked: null,
  })
  const [megaMenu, setMegaMenu] = useState(false)
  const handlerMenuMobile = () => {
    disabledMenu()
    if (mobileMenu.initial === false) {
      setMobileMenu({ initial: null, clicked: true })
    } else if (mobileMenu.clicked === true) {
      setMobileMenu({ clicked: !mobileMenu.clicked })
    } else if (mobileMenu.clicked === false) {
      setMobileMenu({ clicked: !mobileMenu.clicked })
    }
  }
  // Toggler disabled state & handler
  const [disabled, setDisabled] = useState(false)
  const disabledMenu = () => {
    setDisabled(!disabled)
    setTimeout(() => {
      setDisabled(false)
    }, 1000)
  }
  // Onscroll

  useEffect(() => {
    const navbar = document.querySelector(".navbar")
    const containsClass = navbar.classList.contains("light")

    window.onscroll = function() {
      if (!containsClass) {
        if (window.pageYOffset > 5) {
          setNavScroll(true)
        } else {
          setNavScroll(false)
        }
      }
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      window.gigya.accounts.addEventHandlers({
        onLogin: eventData => {
          setUser(eventData.profile)
          navigate("/profile/")
        },
      })
    }, 5000)
  }, [])

  const logoutResponse = response => {
    if (response.errorCode == 0) {
      navigate("/login/")
      setUser(null)
    } else {
      alert("Error :" + response.errorMessage)
    }
  }

  const handlerLogout = () => {
    window.gigya.accounts.logout({
      callback: logoutResponse,
    })
  }

  return (
    <>
      <div className="navbar-wrapper fixed-top">
        {/* When giving .navbar-expand-*, don't forget to modify the variables on _navbar.scss */}
        <nav
          className={`navbar navbar-expand-${navExpand} transparent dark 
          ${navScroll ? "is-scrolled" : ""} ${
            megaMenu ? "nav-menu-active" : ""
          }`}
        >
          <div className="container position-relative alt-menus">
            <Link className="navbar-brand" to="/">
              <img
                src={ImgNavbarLogoDark}
                className="img-fluid dark"
                alt="Logo"
                width="auto"
              />
              <img
                src={ImgNavbarLogoLight}
                className="img-fluid light"
                alt="Logo"
                width="auto"
              />
            </Link>
            <div className="navbar-toggler">
              <div className="d-block d-md-none">
                {/* prettier-ignore */}
                <button className="nav-link nav-link-icon search-toggler" onClick={handlerSearch}>
                  <i className="fal fa-search icon-search" />
                </button>
              </div>
              <button
                className=""
                disabled={disabled}
                onClick={handlerMenuMobile}
              >
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            </div>

            <div className={`navbar-menu light d-${navExpand}-block`}>
              <div className="navbar-menu-bg"></div>
              <div className="navbar-menu-content">
                <div className="navbar-collapse">
                  <ul className="navbar-nav ml-auto">
                    {data.wordPress.menus.nodes.map((menuSections, i) => {
                      // Navbar ENGLISH
                      if (
                        lang === "EN" &&
                        menuSections.slug === "navbar-menu"
                      ) {
                        return menuSections.menuItems.nodes.map((menu, j) => {
                          if (
                            menu.url === "#" &&
                            menu.label.toLowerCase().includes("about")
                          ) {
                            return (
                              <li
                                className={`nav-item ${search &&
                                  "active-search"}`}
                                key={`${menu.label}${j}`}
                              >
                                <div>
                                  <button
                                    className="nav-link dropdown-toggle"
                                    disabled={aboutSubmenuToggler}
                                    onClick={() => {
                                      handleAboutDesktopSubmenu()
                                    }}
                                  >
                                    {menu.label}
                                  </button>
                                </div>
                              </li>
                            )
                          } else if (
                            menu.url === "#" &&
                            menu.label.toLowerCase().includes("development")
                          ) {
                            return (
                              <li
                                className={`nav-item ${search &&
                                  "active-search"}`}
                                key={`${menu.label}-${j}`}
                              >
                                <div>
                                  <button
                                    className="nav-link dropdown-toggle"
                                    disabled={submenuToggler}
                                    onClick={() => {
                                      handleDesktopSubmenu()
                                    }}
                                  >
                                    {menu.label}
                                  </button>
                                </div>
                              </li>
                            )
                          } else if (
                            menu.url === "#" &&
                            menu.label.toLowerCase().includes("services")
                          ) {
                            return (
                              <li
                                className={`nav-item ${search &&
                                  "active-search"}`}
                                key={`${menu.label}-${j}`}
                              >
                                <div>
                                  <button
                                    className="nav-link dropdown-toggle"
                                    disabled={servicesSubmenuToggler}
                                    onClick={() => {
                                      handleServicesDesktopSubmenu()
                                    }}
                                  >
                                    {menu.label}
                                  </button>
                                </div>
                              </li>
                            )
                          }
                          return (
                            <li
                              className={`nav-item ${search &&
                                "active-search"}`}
                            >
                              <div>
                                <Link
                                  className="nav-link"
                                  activeClassName="active"
                                  to={
                                    menu.label.toLowerCase().includes("home")
                                      ? menu.url
                                      : menu.url + "/"
                                  }
                                  key={`${menu.label}-${j}`}
                                >
                                  {menu.label}
                                </Link>
                              </div>
                            </li>
                          )
                        })
                        // Navbar INDO
                      } else if (
                        lang === "ID" &&
                        (menuSections.slug === "navbar-menu-indonesia" ||
                          menuSections.slug === "navbar-menu-id")
                      ) {
                        return menuSections.menuItems.nodes.map((menu, j) => {
                          if (
                            menu.url === "#" &&
                            menu.label.toLowerCase().includes("tentang")
                          ) {
                            return (
                              <li
                                className={`nav-item ${search &&
                                  "active-search"}`}
                                key={`${menu.label}${j}`}
                              >
                                <div>
                                  <button
                                    className="nav-link dropdown-toggle"
                                    disabled={aboutSubmenuToggler}
                                    onClick={() => {
                                      handleAboutDesktopSubmenu()
                                    }}
                                  >
                                    {menu.label}
                                  </button>
                                </div>
                              </li>
                            )
                          } else if (
                            menu.url === "#" &&
                            (menu.label.toLowerCase().includes("embangan") ||
                              menu.label.toLowerCase().includes("pembangun"))
                          ) {
                            return (
                              <li
                                className={`nav-item ${search &&
                                  "active-search"}`}
                                key={`${menu.label}-${j}`}
                              >
                                <div>
                                  <button
                                    className="nav-link dropdown-toggle"
                                    disabled={submenuToggler}
                                    onClick={() => {
                                      handleDesktopSubmenu()
                                    }}
                                  >
                                    {menu.label}
                                  </button>
                                </div>
                              </li>
                            )
                          } else if (
                            (menu.url === "#" &&
                              menu.label.toLowerCase().includes("layanan")) ||
                            menu.label.toLowerCase().includes("jasa")
                          ) {
                            return (
                              <li
                                className={`nav-item ${search &&
                                  "active-search"}`}
                                key={`${menu.label}-${j}`}
                              >
                                <div>
                                  <button
                                    className="nav-link dropdown-toggle"
                                    disabled={servicesSubmenuToggler}
                                    onClick={() => {
                                      handleServicesDesktopSubmenu()
                                    }}
                                  >
                                    {menu.label}
                                  </button>
                                </div>
                              </li>
                            )
                          }
                          return (
                            <li
                              className={`nav-item ${search &&
                                "active-search"}`}
                            >
                              <div>
                                <Link
                                  className="nav-link"
                                  activeClassName="active"
                                  to={
                                    menu.label.toLowerCase().includes("beranda")
                                      ? menu.url
                                      : menu.url + "/"
                                  }
                                  key={`${menu.label}-${j}`}
                                >
                                  {menu.label}
                                </Link>
                              </div>
                            </li>
                          )
                        })
                      }
                    })}
                    <li className={`nav-item ${search && "active-search"}`}>
                      <div>
                        <Link
                          className="nav-link"
                          activeClassName="active"
                          to="/medical-hub"
                        >
                          Medical Hub
                        </Link>
                      </div>
                    </li>
                    <li className={`nav-item ${search && "active-search"}`}>
                      <div>
                        <Link
                          className="nav-link"
                          activeClassName="active"
                          to="https://ecatalog.sinarmasland.com?utm_source=Website&utm_medium=BSDWebsite&utm_campaign=Digital_e-Catalog_2021&utm_content=ECatalog_BSD_City_Website_Menu"
                          target="_blank"
                        >
                          E-Catalog
                        </Link>
                      </div>
                    </li>
                    <li className={`nav-item`}>
                      <div>
                        <form
                          action={`${lang === "EN" ? "" : "/id"}/search-result`}
                          className={`form-search ${search && "is-open"}`}
                        >
                          <input
                            className="form-control"
                            name="key"
                            id="key"
                            type="text"
                            placeholder={lang == "EN" ? "Search" : "Cari"}
                          />
                        </form>
                      </div>
                    </li>
                    <li className="nav-item" onClick={clickSearch}>
                      <div>
                        {/* prettier-ignore */}
                        <button className="nav-link nav-link-icon" aria-label="toggler-search">
                          <i className="fal fa-search" />
                        </button>
                      </div>
                    </li>
                    {!user ? (
                      <>
                        {/* <li className={`nav-item ml-3`}>
                          <div>
                            <Link className="btn btn-secondary login-btn-header" to="/login">
                              Login
                            </Link>
                          </div>
                        </li> */}
                        <li className={`nav-item ml-3`}>
                          <div>
                            <Link
                              className="btn btn-secondary register-btn-header"
                              to="/register/"
                            >
                              Register
                            </Link>
                          </div>
                        </li>
                        <li className={`nav-item ml-3`}>
                          <div>
                            <Link
                              className="btn btn-secondary subscribe-btn-header"
                              to="/subscribe/"
                            >
                              Subscribe
                            </Link>
                          </div>
                        </li>
                      </>
                    ) : (
                      <>
                        <li className={`nav-item`}>
                          <div>
                            <Link className="nav-link" to="/profile/">
                              Profile
                            </Link>
                          </div>
                        </li>
                        <li className={`nav-item`}>
                          <div>
                            <button
                              className="nav-link"
                              onClick={() => handlerLogout()}
                            >
                              Logout
                            </button>
                          </div>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <MobileMenuAlt
              mobileMenu={mobileMenu}
              navExpand={navExpand}
              data={data}
              handlerLogout={handlerLogout}
            />
          </div>
        </nav>
        <form
          action="/search-result"
          className={`form-search mobile d-block d-md-none ${search &&
            "active"}`}
        >
          <input
            className="form-control"
            name="key"
            id="key"
            type="text"
            placeholder="Type to search"
          />
          <i className="fal fa-times" onClick={handlerSearch}></i>
        </form>
      </div>
    </>
  )
}

export default NavbarAlt
